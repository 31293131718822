import { Box, Card, Typography } from '@mui/material';
import { OemPartnerNames } from '../../utils/types';
import { OEM_PARTNER_LOGO_FILES } from '../../utils/consts';

const renderOemLogos = (oems: OemPartnerNames[]) => {
  return oems.map(oem => {
    // check if oem exists in OEM_PARTNER_LOGO_URLS
    const url = OEM_PARTNER_LOGO_FILES[oem];
    if (!url) {
      console.error('OEM logo not found for: ', oem);
      return null;
    }

    return (
      <Box
        component="img"
        data-testid={`oem-logo`}
        sx={{
          width: 140,
          height: 'auto',
        }}
        alt={oem}
        src={url}
        key={oem}
      />
    );
  });
};

type BillboardProps = {
  oemsToDisplay: OemPartnerNames[];
};

const Billboard = ({ oemsToDisplay }: BillboardProps) => {
  return (
    <Card
      sx={{
        padding: 7,
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 5,
      }}
    >
      {/* TODO: When theme is unified across apps, this typography component should use "primary" instead of hardcoded color hex */}
      <Typography
        variant="h4"
        color="primary"
        sx={{ marginBottom: 3, color: '#EF6A11' }}
      >
        <strong>
          Brain Corp works with leading global manufacturers that companies
          already know and trust
        </strong>
      </Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: 6, fontSize: 18 }}>
        — meaning businesses don’t have to compromise on machine performance for
        autonomous functionality.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {renderOemLogos(oemsToDisplay)}
      </Box>
    </Card>
  );
};

export default Billboard;
