import { useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material';
import { useLocalization } from '../../providers/localization-provider/localization-context';
import { getLocaleItem } from '../../providers/localization-provider/localization-utils';
import { LocaleList } from '../../providers/localization-provider/localization-types';
import { FormattedMessage } from 'react-intl';
import LOCALE_LIST from '../../providers/localization-provider/locale-list';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import { MaxWidthRowLayout } from '../styled';
import { HEADER_HEIGHT } from '../../utils/consts';
import BrainCorpLogo from '../../assets/product-logos/brain-corp-logo';
import UserMenu from './user-menu';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../auth/auth-context';

const RenderLocaleList = ({ localeList }: { localeList: LocaleList }) => {
  const localeArray = localeList.map(localeItem => {
    if ('value' in localeItem) {
      return (
        <MenuItem key={`${localeItem.label}`} value={localeItem.value}>
          {localeItem.flag} {localeItem.label}
        </MenuItem>
      );
    } else {
      return (
        <ListSubheader key={`${localeItem.label}`}>
          <FormattedMessage id={localeItem.label} />
        </ListSubheader>
      );
    }
  });

  return <>{localeArray}</>;
};

const Header = () => {
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up('sm'));
  const [{ locale /*isEmbedded*/ }, dispatch] = useLocalization();
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [{ user }] = useAuth();

  const renderRegionValue = (value: string) => {
    const localeItem = getLocaleItem(value);
    return isWide ? `${localeItem.flag} ${localeItem.label}` : localeItem.flag;
  };

  const handleLocaleChange: SelectInputProps<any>['onChange'] = event => {
    const locale = getLocaleItem(event.target.value);
    searchParams.set('locale', locale.value);
    setSearchParams(searchParams);
    dispatch({
      action: 'SET',
      payload: { locale },
    });
  };

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // if (isEmbedded) {
  //   // adding extra space on top to account for mobile top bar
  //   return <Box sx={{ p: 2 }} />;
  // }

  return (
    <AppBar
      elevation={0}
      color="transparent"
      component="nav"
      sx={{
        height: `${HEADER_HEIGHT}px`,
        width: '100%',
        position: 'relative',
        flexDirection: 'column', // this is MUI default, but just to be explicit
        alignItems: 'center',
        // ...rootAndHeaderStyles(theme),
      }}
    >
      <MaxWidthRowLayout>
        <BrainCorpLogo
          data-testid={'headerLogo'}
          sx={{ width: 'auto', height: '40px' }}
        />
        {/* box that contains the language select and user button */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FormControl>
            {isWide && <InputLabel id="select-label">Region</InputLabel>}
            <Select
              id="locale-select"
              sx={() => ({
                borderRadius: 10,
                color: theme.palette.text.primary,
              })}
              label={isWide ? 'Region' : undefined}
              value={locale.value}
              renderValue={renderRegionValue}
              onChange={handleLocaleChange}
            >
              <RenderLocaleList localeList={LOCALE_LIST} />
            </Select>
          </FormControl>
          <ButtonBase
            onClick={handleUserMenuClick}
            data-testid="user"
            sx={{ marginLeft: 3 }}
          >
            <StyledAvatar>{user?.email && user.email[0]}</StyledAvatar>
          </ButtonBase>
          <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </Box>
      </MaxWidthRowLayout>
    </AppBar>
  );
};

const StyledAvatar = styled(Avatar)(() => ({
  textTransform: 'uppercase',
  lineHeight: 'unset', // center the text avatar vertically
  // backgroundColor: '#18232A',
}));

export default Header;
